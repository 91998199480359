import 'lazysizes';

//bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import './src/styles/styles.css';

require('prismjs/themes/prism-tomorrow.css');

import CustomLayout from './wrapPageElement';

export const wrapPageElement = CustomLayout;
