// Only one item MUST have the "default: true" key

module.exports = {
    en: {
        default: true,
        path: `en`,
        locale: `en-US`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
        defaultTitle: `NotMilk Coffee`,
        defaultDescription: `Discover new recipes made by NotCo's chefs.`,
    },
    pt: {
        path: `pt`,
        locale: `pt-BR`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `pt`,
        ogLanguage: `pt_BR`,
        defaultTitle: `NotMilk Coffee`,
        defaultDescription: `Descubra novas receitas feitas pelos chefs do NotCo.`,
    },
    es: {
        path: `es`,
        locale: `es-CL`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `es`,
        ogLanguage: `es_CL`,
        defaultTitle: `Recetas de NotMilk`,
        defaultDescription: `Descubre nuevas recetas hechas por los chefs de NotCo.`,
    },
}
